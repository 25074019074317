import React, { useEffect, useState, useContext } from 'react';
import {
    PartnershipTabs,
    Nav,
    Input,
    Select,
    MultiSelect,
    ExpandableNotice,
    DropdownMultipleCombobox,
    FAMAlert,
} from '../../shared';
import { PackGroupList } from './components/packGroupList.js';
import { partnershipsApi } from '../../../api/partnerships.js';
import { marketplaceApi } from '../../../api/marketplace.js';
import { catalogApi } from '../../../api/catalog.js';
import { UserContext } from '../../../contexts/UserContext';
import './createList.css';
import { useLocation } from 'react-router-dom';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { Alert } from '@material-ui/lab';
import { Dialog, DialogContent, Checkbox, FormControlLabel } from '@material-ui/core';
import { SkusRemovedFromList } from './components/skusRemovedFromListModal.js';

const CreateList = () => {
    // Initial empty state
    const initialTaxonomySelections = { l1: [], l2: [], l3: [], l4: [] };
    const initialPackGroupObject = {
        packGroupID: null,
        facilityID: null,
        facilityName: '',
        allItems: [],
    };
    const initialList = {
        id: null,
        type: 'Item',
        name: '',
        priceVariance: '',
        minPrice: '',
        maxPrice: '',
        slots: {},
    };

    const { state } = useLocation();
    const { user, famAccount } = useContext(UserContext);
    const [packGroupListTabs, setPackGroupListTabs] = useState([]);
    const [week, setWeek] = useState(state?.week || '');
    const [year, setYear] = useState(state?.year || '');
    const [successMessage, setSuccessMessage] = useState('');
    const [addItemError, setAddItemError] = useState('');
    const [listInfoError, setListInfoError] = useState('');
    const [editingName, setEditingName] = useState(false);
    const [editingPriceVariance, setEditingPriceVariance] = useState(false);
    const [editingMinPrice, setEditingMinPrice] = useState(false);
    const [editingMaxPrice, setEditingMaxPrice] = useState(false);
    const [removeSlotModalOpen, setRemoveSlotModalOpen] = useState(false);

    // Array of current pack groups, with facilityName, facilityID, packGroupID, allItems
    const [packGroupsAndItems, setPackGroupsAndItems] = useState([]);
    // The entire list being created or edited
    const [currentList, setCurrentList] = useState(initialList);

    // Items selected for the current slot that haven't been added/saved to currentList yet
    const [selectedItemsByPackGroup, setSelectedItemsByPackGroup] = useState({});

    // Options for select components
    const [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    const [commonItems, setCommonItems] = useState([]);
    const [slotOptions, setSlotOptions] = useState([]);

    // FORM SELECTIONS
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [selectedItemsToAdd, setSelectedItemsToAdd] = useState([]);
    const [currentSlot, setCurrentSlot] = useState('');
    const [currentSlotRequired, setCurrentSlotRequired] = useState(false);
    const [taxonomySelections, setTaxonomySelections] = useState(initialTaxonomySelections);
    const [taxonomySlotOrderBy, setTaxonomySlotOrderBy] = useState('popularity');

    // For selected item modal
    const [selectedItemDetails, setSelectedItemDetails] = useState(null);
    const [resetItemInputValue, setResetItemInputValue] = useState(null);

    const [skusRemoved, setSkusRemoved] = useState([]);

    useEffect(() => {
        async function getTaxonomy() {
            const promises = [1, 2, 3, 4].map((lvl) => catalogApi.getTaxonomyByLevel(lvl, 'TAXONOMY'));
            const res = await Promise.all(promises);
            if (res.every((item) => item.status)) {
                setTaxonomy({
                    l1: res[0].data.categories.filter((item) => item.name !== 'FBM'),
                    l2: res[1].data.categories,
                    l3: res[2].data.categories,
                    l4: res[3].data.categories,
                });
            }
        }

        async function getPackGroupItems(packGroups) {
            const res = await partnershipsApi.getItemsWithTaxonomy(famAccount.partnershipSource);
            if (!res.status) {
                return;
            }
            setWeek(res.data.week);
            setYear(res.data.year);
            let tmp = [];
            let selectedItemTmp = {};
            packGroups.forEach((pg) => {
                let row = res.data.rows.find((item) => item.facilityID === pg.facilityID);
                tmp.push({ ...pg, packGroupID: row.packGroupID, allItems: row.items });
                selectedItemTmp[row.packGroupID] = [];
            });
            setPackGroupsAndItems(tmp);

            // set up the pack group keys here
            setSelectedItemsByPackGroup(selectedItemTmp);
        }

        const initialPackGroups = famAccount.facilities.map((fc) => ({
            ...initialPackGroupObject,
            facilityID: fc.id,
            facilityName: fc.name,
        }));
        getPackGroupItems(initialPackGroups);
        getTaxonomy();
    }, [famAccount.partnershipSource]);

    const clearSelectedItemsByPackGroup = () => {
        let tmp = {};
        packGroupsAndItems.forEach((pg) => {
            tmp[pg.packGroupID] = [];
        });
        setSelectedItemsByPackGroup(tmp);
    };

    const resetList = () => {
        setCurrentList(initialList);
        clearSelectedItemsByPackGroup();
    };

    useEffect(() => {
        const getList = async (listID) => {
            const res = await partnershipsApi.getCuratedListsForPartner(famAccount.partnershipSource, listID);
            if (!res.status || !res.data || !res.data.lists.length) {
                return;
            }

            let list = res.data.lists[0];
            list.notices = {};

            Object.keys(list.slots).forEach((slotNumber) => {
                list.slots[slotNumber].packGroups.forEach((pg) => {
                    let facilityName = res.data.packGroups.find((item) => item.id === pg.packGroupID)?.facility;
                    let emptyItems = pg.items.filter((item) => !item.externalSku);
                    if (emptyItems.length && !list.notices.hasOwnProperty(pg.packGroupID)) {
                        list.notices[pg.packGroupID] = [];
                    }
                    emptyItems.forEach((item) => {
                        list.notices[pg.packGroupID].push(
                            `FC ${facilityName} - Slot ${slotNumber} Position ${item.position} is empty`
                        );
                    });
                });
            });

            setCurrentList(list);
        };

        if (state?.list?.id) {
            getList(state.list.id);
        }
    }, [state]);

    const downloadActiveCatalog = () => {
        partnershipsApi.getItemsWithTaxonomyCSV(famAccount.partnershipSource);
    };

    useEffect(() => {
        getSlotOptions();
    }, [currentList]);

    useEffect(() => {
        if (taxonomySelections.fromExistingList) {
            return;
        }

        if (currentList.type === 'Taxonomy') {
            fillSlotWithItemsWithTaxonomyID();
        }
    }, [taxonomySelections]);

    useEffect(() => {
        if (!addItemError) {
            return;
        }
        setTimeout(() => {
            setAddItemError('');
        }, 5000);
    }, [addItemError]);

    useEffect(() => {
        if (!listInfoError) {
            return;
        }
        setTimeout(() => {
            setListInfoError('');
        }, 5000);
    }, [listInfoError]);

    useEffect(() => {
        const tabs = packGroupsAndItems.map((pg) => {
            return {
                name: pg.facilityName,
                component: (
                    <PackGroupList
                        showItemDetails={(item) => {
                            let itemDetails = pg.allItems.find((val) => val.externalSku === item.externalSku);
                            setSelectedItemDetails(itemDetails);
                        }}
                        key={pg.packGroupID}
                        packGroupID={pg.packGroupID}
                        positions={selectedItemsByPackGroup?.[pg.packGroupID] || []}
                        handleRemoveItem={handleRemoveItem}
                        handleRepositionItem={handleRepositionItem}
                        handleUpdateQuantity={handleUpdateQuantity}
                    />
                ),
            };
        });
        setPackGroupListTabs(tabs);
    }, [packGroupsAndItems, selectedItemsByPackGroup]);

    useEffect(() => {
        getCommonItemsAcrossFacilities(selectedFacilities);
    }, [selectedFacilities, taxonomySelections, currentList.type]);

    const updateCurrentList = ({ key, value }) => {
        if (key === 'type') {
            setCurrentList({ ...currentList, [key]: value, priceVariance: '', minPrice: '', maxPrice: '' });
        } else {
            setCurrentList({ ...currentList, [key]: value });
        }
    };

    const refreshList = async () => {
        const res = await partnershipsApi.getCuratedListsForPartner(famAccount.partnershipSource, currentList.id);
        if (!res.status || !res.data) {
            return;
        }
        setCurrentList(res.data.lists[0]);
    };

    // Get options, taxonomy, items, etc
    const getSlotOptions = () => {
        let existingSlots = Object.keys(currentList.slots)
            .map((slotNumber) => ({ text: slotNumber, value: slotNumber }))
            .sort((a, b) => (a > b ? -1 : 1));

        let newSlot = Object.keys(currentList.slots).length + 1;
        existingSlots.unshift({ value: String(newSlot), text: `${newSlot} (New Slot)` });
        setSlotOptions(existingSlots);
    };

    const resetSlot = (newSlot = null) => {
        // If a new slot was passed in, move to that slot
        if (newSlot !== null) {
            setCurrentSlot(newSlot);
        }

        if (!newSlot) {
            return;
        }

        // Reset the selected items and taxonomy based on what's in the currentList for this slot
        if (currentList.slots.hasOwnProperty(newSlot)) {
            let newSlotCurrent = JSON.parse(JSON.stringify(currentList.slots[newSlot]));
            let selectedItemTmp = {};
            packGroupsAndItems.forEach((pg) => {
                const packGroupID = pg.packGroupID;
                selectedItemTmp[packGroupID] =
                    newSlotCurrent.packGroups?.find((item) => packGroupID === item.packGroupID)?.items || [];
            });
            setSelectedItemsByPackGroup(selectedItemTmp);

            if (newSlotCurrent.hasOwnProperty('taxonomyIDs')) {
                const level = newSlotCurrent.taxonomyLevel;
                let ids = newSlotCurrent.taxonomyIDs;
                let selectedTx = {
                    fromExistingList: true,
                };

                for (let lvl = level; lvl > 0; lvl--) {
                    const txs = taxonomy[`l${lvl}`].filter((item) => ids.includes(item.id));
                    selectedTx[`l${lvl}`] = txs.map((tx) => tx.id);
                    ids = [...new Set(txs.map((tx) => tx.parentID))];
                }
                setTaxonomySelections({ ...initialTaxonomySelections, ...selectedTx });
                setTaxonomySlotOrderBy(newSlotCurrent.taxonomyOrderBy);
            }

            if (newSlotCurrent.hasOwnProperty('required')) {
                setCurrentSlotRequired(newSlotCurrent.required);
            }
        } else {
            // If the current list doesn't have anything for this slot number, clear taxonomy and selected items
            clearSelectedItemsByPackGroup();
            setTaxonomySelections(initialTaxonomySelections);
        }
    };

    const getCommonItemsAcrossFacilities = (facilityIDs) => {
        if (!packGroupsAndItems.length || !facilityIDs.length) {
            setCommonItems([]);
            return;
        }

        // find taxonomy to filter out
        let { ids, level } = getSelectedTaxonomyIDsAndLevel();
        let taxonomyKey = '';
        if (ids.length > 0) {
            taxonomyKey = `l${level}TaxonomyID`;
        }

        const invFiltered = packGroupsAndItems
            .filter((pg) => facilityIDs.includes(pg.facilityID))
            .map((pg) =>
                currentList.type !== 'Item' || !ids.length
                    ? pg.allItems
                    : pg.allItems.filter((item) => ids.includes(item[taxonomyKey]))
            );

        let flattened = invFiltered.reduce((a, b) => a.concat(b), []);
        let counts = flattened.reduce(
            (map, { externalSku }) => map.set(externalSku, (map.get(externalSku) || 0) + 1),
            new Map()
        );

        let common = invFiltered[0].filter(({ externalSku }) => {
            if (counts.get(externalSku) === facilityIDs.length) {
                return true;
            }
            return false;
        });

        setCommonItems(common);
    };

    const getSelectedTaxonomyIDsAndLevel = () => {
        let selectedIDs = [];
        let selectedLevel = '';
        let selectedNames = [];

        const levels = [4, 3, 2, 1];
        for (let level of levels) {
            let key = `l${level}`;
            let taxOptions = taxonomy[key];
            if (!!taxonomySelections[key] && taxonomySelections[key].length > 0) {
                selectedIDs = taxonomySelections[key].map((id) => Number(id));
                selectedNames = taxonomySelections[key].map((id) => {
                    const option = taxOptions.find((item) => item.id === Number(id));
                    return option?.name;
                });
                selectedLevel = level;
                break;
            }
        }

        return { ids: selectedIDs, level: selectedLevel, names: selectedNames };
    };

    // Making changes to the current list

    const addSelectedItemToSelectedPackGroups = () => {
        if (selectedItemsToAdd.length && selectedFacilities.length) {
            // Add to selected facilities
            let tmp = {};

            const selectedFacilityIDs = selectedFacilities;
            packGroupsAndItems.forEach((pg) => {
                let tmpItems = JSON.parse(JSON.stringify(selectedItemsByPackGroup?.[pg.packGroupID])) || [];

                if (selectedFacilityIDs.includes(pg.facilityID)) {
                    selectedItemsToAdd.forEach((selectedItemToAdd) => {
                        let item = pg.allItems.find((item) => item.externalSku === selectedItemToAdd);

                        let emptyItemIndex = tmpItems.findIndex((item) => !item.externalSku);
                        if (emptyItemIndex >= 0) {
                            // If there is an empty position, fill that one in
                            tmpItems[emptyItemIndex] = { ...tmpItems[emptyItemIndex], ...item };
                        } else {
                            // If not, add a new position
                            let newPosition = tmpItems.length + 1;

                            tmpItems.push({ ...item, qty: 1, positionSource: 'item', position: newPosition });
                        }
                    });
                }

                tmp[pg.packGroupID] = tmpItems;
            });

            setSelectedItemsByPackGroup(tmp);
            setSelectedItemsToAdd([]);
        }
    };

    const saveCuratedListInfoChanges = async (key) => {
        setListInfoError('');
        let res = await marketplaceApi.editCuratedListInfo(currentList.id, { [key]: currentList[key] });
        if (!res.status) {
            setListInfoError(`Failed to save changes to list: ${res.msg}`);
        } else {
            setSuccessMessage('Changes saved!');
        }
        refreshList();
    };

    const handleRemoveSlot = async () => {
        if (!currentSlot || !currentList.slots.hasOwnProperty(currentSlot)) {
            return;
        }

        if (!currentList.id) {
            // reorder everything ( if slot is > slot we are removing, move it back one )
            let tmpCurrentList = JSON.parse(JSON.stringify(currentList));
            let newSlots = {};
            const currSlotNum = Number(currentSlot);

            Object.keys(tmpCurrentList.slots).forEach((slotNumber) => {
                const slot = Number(slotNumber);
                if (slot < currSlotNum) {
                    newSlots[slot] = tmpCurrentList.slots[slot];
                } else if (slot > currSlotNum) {
                    newSlots[slot - 1] = tmpCurrentList.slots[slot];
                }
            });

            tmpCurrentList.slots = newSlots;
            setCurrentList(tmpCurrentList);
        } else {
            // delete on the backend and refresh the list
            let res = await partnershipsApi.removeSlotFromPartnershipList(currentList.id, currentSlot);
            if (!res.status) {
                setAddItemError(`Failed to remove slot: ${res.msg}`);
                return;
            }
            refreshList();
        }

        // Just go to first slot in list?
        resetSlot('1');
        setSuccessMessage('Slot removed from list!');
        setRemoveSlotModalOpen(false);
    };

    const handleAddSlotToList = async () => {
        if (!currentSlot) {
            setAddItemError('Please select a slot number');
            return;
        }

        let obj = {
            required: currentSlotRequired,
            packGroups: packGroupsAndItems.map((pg) => ({
                packGroupID: pg.packGroupID,
                facilityID: pg.facilityID,
                facilityName: pg.facilityName,
                items: selectedItemsByPackGroup?.[pg.packGroupID] || [],
            })),
        };

        let packGroupsMissingItems = obj.packGroups.filter((pg) => !pg.items.length).map((pg) => pg.facilityName);
        if (packGroupsMissingItems.length === obj.packGroups.length) {
            setAddItemError(`Must add items to slot for at least one facility`);
            return;
        }

        let { ids, level, names } = getSelectedTaxonomyIDsAndLevel();
        if (!!ids.length) {
            obj.taxonomyIDs = ids;
            obj.taxonomyLevel = level;
            obj.taxonomyOrderBy = taxonomySlotOrderBy;
            obj.taxonomyNames = names;
        }

        if (currentList.type === 'Taxonomy' && (!obj.hasOwnProperty('taxonomyIDs') || !obj.taxonomyIDs.length)) {
            setAddItemError('Please select a taxonomy for this slot');
            return;
        }

        if (!currentList.id) {
            let tmpCurrentList = JSON.parse(JSON.stringify(currentList));
            tmpCurrentList.slots[currentSlot] = obj;

            // If we added a new slot to the list (didn't edit an existing slot, we want to move to the next one)
            if (!Object.keys(currentList.slots).includes(currentSlot)) {
                resetSlot(String(Object.keys(tmpCurrentList.slots).length + 1));
            }
            setCurrentList(tmpCurrentList);
        } else {
            let res = await partnershipsApi.addSlotToPartnershipList(
                currentList.id,
                currentSlot,
                obj.required,
                obj.packGroups,
                obj.taxonomyIDs,
                obj.taxonomyLevel,
                obj.taxonomyOrderBy
            );
            if (!res.status) {
                setAddItemError(`Failed to save changes to slot: ${res.msg}`);
                return;
            }
            // If we added a new slot to the list (didn't edit an existing slot), we want to move to the next one
            if (!Object.keys(currentList.slots).includes(currentSlot)) {
                resetSlot(String(Object.keys(currentList.slots).length + 2));
            }
            refreshList();
            if (res.data?.skusRemoved?.length > 0 && currentList.type === 'Taxonomy') {
                setSkusRemoved(res.data.skusRemoved);
            }
        }
        setSuccessMessage('Changes to slot have been saved!');
    };

    const handleCreateList = async () => {
        const { name, type, priceVariance, slots, minPrice, maxPrice } = currentList;
        const slotCopy = JSON.parse(JSON.stringify(slots));

        const slotsArr = [];

        Object.keys(slots).forEach((slotNumber) => {
            let slotParam = slotCopy[slotNumber];
            slotParam.packGroups = slotParam.packGroups.map((pg) => {
                pg.items = pg.items.map((item) => ({
                    externalSku: item.externalSku,
                    qty: item.qty,
                    position: item.position,
                }));
                return pg;
            });

            slotsArr.push({ slot: slotNumber, ...slotParam });
        });

        const sendParams = {
            name,
            type,
            priceVariance,
            minPrice,
            maxPrice,
            slots: slotsArr,
            source: famAccount.partnershipSource,
        };

        const res = await partnershipsApi.createCuratedList(sendParams);
        if (!res.status) {
            setAddItemError(`Failed to create list. ${res.msg}`);
            return;
        }

        // Show a success message
        setSuccessMessage(`List ${name} created!`);
        resetList();
    };

    const handleTaxonomyOrderByChange = (newTaxonomySlotOrderBy) => {
        let tmp = {};

        packGroupsAndItems.forEach((pg) => {
            let tmpItems = JSON.parse(JSON.stringify(selectedItemsByPackGroup?.[pg.packGroupID])) || [];
            tmpItems = tmpItems
                .sort((a, b) => a[newTaxonomySlotOrderBy] - b[newTaxonomySlotOrderBy])
                .map((item, index) => ({
                    ...item,
                    position: index + 1,
                }));
            tmp[pg.packGroupID] = tmpItems;
        });

        setSelectedItemsByPackGroup(tmp);
        setTaxonomySlotOrderBy(newTaxonomySlotOrderBy);
    };

    const fillSlotWithItemsWithTaxonomyID = () => {
        let tmp = {};

        let { ids, level } = getSelectedTaxonomyIDsAndLevel();

        if (!ids.length) {
            return;
        }

        packGroupsAndItems.forEach((pg) => {
            let tmpItems = JSON.parse(JSON.stringify(selectedItemsByPackGroup?.[pg.packGroupID])) || [];
            const addedItems = tmpItems.filter((item) => item.positionSource === 'item');
            let itemsWithTaxonomy = [];
            const key = `l${level}TaxonomyID`;

            let orderBy = taxonomySlotOrderBy;
            let excludedSkus = currentList?.excludedSkus?.map((item) => item.externalSku) || [];
            itemsWithTaxonomy = pg.allItems
                .filter((item) => ids.includes(item[key]) && !excludedSkus.includes(item.externalSku))
                .map((item) => ({ ...item, qty: 1, positionSource: 'taxonomy' }))
                .sort((a, b) => a[orderBy] - b[orderBy]);

            tmpItems = [...itemsWithTaxonomy, ...addedItems].map((item, index) => ({
                ...item,
                position: index + 1,
            }));
            tmp[pg.packGroupID] = tmpItems;
        });

        setSelectedItemsByPackGroup(tmp);
    };

    const handleRemoveItem = (packGroupID, itemToRemove) => {
        let tmp = JSON.parse(JSON.stringify(selectedItemsByPackGroup));

        let tmpItems = JSON.parse(JSON.stringify(selectedItemsByPackGroup[packGroupID]));
        tmpItems = tmpItems
            .filter((item) => item.externalSku !== itemToRemove.externalSku)
            .map((item, index) => ({ ...item, position: index + 1 }));

        tmp[packGroupID] = tmpItems;

        setSelectedItemsByPackGroup(tmp);
    };

    const handleRepositionItem = (packGroupID, oldPosition, newPosition) => {
        let tmp = JSON.parse(JSON.stringify(selectedItemsByPackGroup));
        let tmpItems = JSON.parse(JSON.stringify(selectedItemsByPackGroup[packGroupID]));

        // Don't allow any gaps in position
        if (newPosition > tmpItems.length) {
            return;
        }

        let direction = oldPosition < newPosition ? 'down' : 'up';

        tmpItems = tmpItems
            .map((item) => {
                // are we moving up or down
                if (item.position === oldPosition) {
                    item.position = newPosition;
                    return item;
                }

                if (direction === 'down') {
                    if (item.position <= newPosition && item.position > oldPosition) {
                        item.position--;
                    }
                } else if (direction === 'up') {
                    if (item.position >= newPosition && item.position < oldPosition) {
                        item.position++;
                    }
                }
                return item;
            })
            .sort((a, b) => (a.position < b.position ? -1 : 1));

        tmp[packGroupID] = tmpItems;

        setSelectedItemsByPackGroup(tmp);
    };

    const handleUpdateQuantity = (packGroupID, itemToUpdate, quantity) => {
        let tmp = JSON.parse(JSON.stringify(selectedItemsByPackGroup));

        let tmpItems = JSON.parse(JSON.stringify(selectedItemsByPackGroup[packGroupID]));
        tmpItems = tmpItems.map((item) => {
            if (item.externalSku === itemToUpdate.externalSku) {
                item.qty = quantity;
            }
            return item;
        });

        tmp[packGroupID] = tmpItems;

        setSelectedItemsByPackGroup(tmp);
    };

    if (!famAccount?.partnershipSource) {
        return (
            <div className="page-container">
                <Nav />
                <div className="content-container">
                    <div className="width-100">
                        <div className="fam-header-container">
                            <div className="fam-header-item-one">
                                <div className="grotesk-bold header-text">Please Select a Partner</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-container">
            <Nav />
            <SkusRemovedFromList
                skusRemoved={skusRemoved}
                onClose={() => {
                    setSkusRemoved([]);
                }}
                listID={currentList.id}
                listName={currentList.name}
            />
            <Dialog
                open={!!selectedItemDetails}
                onClose={() => {
                    setSelectedItemDetails(null);
                }}
            >
                <DialogContent>
                    {!!selectedItemDetails && !!selectedItemDetails.externalSku && (
                        <div>
                            <ItemDetails item={selectedItemDetails} />
                        </div>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog
                open={removeSlotModalOpen}
                maxWidth="sm"
                fullWidth={true}
                onClose={() => {
                    setRemoveSlotModalOpen(false);
                }}
            >
                <DialogContent>
                    <div className="cancel-order-container">
                        <div>Remove Slot {currentSlot} from list?</div>
                        <div>
                            <button
                                onClick={() => {
                                    handleRemoveSlot();
                                }}
                                className="fam-button fam-button-yellow grotesk-bold"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="content-container">
                <div className="create-list-container">
                    <div className="create-list-header">
                        <div className="create-list-header-item-one">
                            <div className="grotesk-bold header-text">
                                {currentList.id ? 'Edit List' : 'Create List'}
                            </div>
                        </div>
                        <div className="create-list-header-item-two">
                            <button
                                onClick={() => {
                                    downloadActiveCatalog();
                                }}
                                className="fam-button fam-button-yellow grotesk-bold"
                            >
                                Download Active Catalog
                            </button>
                        </div>
                    </div>
                    {currentList.type === 'Taxonomy' && (
                        <div className="grotesk-regular create-list-hint">
                            <ul>
                                <li>A slot can be created with any level taxonomy.</li>
                                <li>
                                    Adding a maximum dollar amount to a taxonomy list may result in not filling all
                                    slots in a list.
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="fam-success-alert">
                        {/* {!!successMessage && <Alert severity="success">{successMessage}</Alert>} */}
                        <FAMAlert severity="success" message={successMessage} />
                    </div>
                    <div className="create-list-form">
                        <form>
                            <div className="create-list-form-row-one">
                                <Input
                                    readOnly={!!currentList.id && !editingName}
                                    label="List Name"
                                    required={true}
                                    value={currentList.name}
                                    onChange={(value) => {
                                        updateCurrentList({ key: 'name', value });
                                    }}
                                    RightIcon={
                                        !currentList.id ? null : editingName ? (
                                            <CheckRoundedIcon
                                                onClick={() => {
                                                    setEditingName(false);
                                                    saveCuratedListInfoChanges('name');
                                                }}
                                                className="partnerships-icon partnerships-gray-icon"
                                            />
                                        ) : (
                                            <EditRoundedIcon
                                                onClick={() => {
                                                    setEditingName(true);
                                                }}
                                                className="partnerships-icon partnerships-gray-icon"
                                            />
                                        )
                                    }
                                />

                                {currentList.type === 'Item' && (
                                    <Input
                                        readOnly={!!currentList.id && !editingPriceVariance}
                                        label="% Over Allowed"
                                        value={currentList.priceVariance}
                                        onChange={(value) => {
                                            updateCurrentList({ key: 'priceVariance', value });
                                        }}
                                        type="number"
                                        RightIcon={
                                            !currentList.id ? null : editingPriceVariance ? (
                                                <CheckRoundedIcon
                                                    onClick={() => {
                                                        setEditingPriceVariance(false);
                                                        saveCuratedListInfoChanges('priceVariance');
                                                    }}
                                                    className="partnerships-icon partnerships-gray-icon"
                                                />
                                            ) : (
                                                <EditRoundedIcon
                                                    onClick={() => {
                                                        setEditingPriceVariance(true);
                                                    }}
                                                    className="partnerships-icon partnerships-gray-icon"
                                                />
                                            )
                                        }
                                    />
                                )}

                                {currentList.type === 'Taxonomy' && (
                                    <>
                                        <Input
                                            readOnly={!!currentList.id && !editingMinPrice}
                                            label="Minimum Price"
                                            value={currentList.minPrice}
                                            onChange={(value) => {
                                                updateCurrentList({ key: 'minPrice', value });
                                            }}
                                            type="number"
                                            RightIcon={
                                                !currentList.id ? null : editingMinPrice ? (
                                                    <CheckRoundedIcon
                                                        onClick={() => {
                                                            setEditingMinPrice(false);
                                                            saveCuratedListInfoChanges('minPrice');
                                                        }}
                                                        className="partnerships-icon partnerships-gray-icon"
                                                    />
                                                ) : (
                                                    <EditRoundedIcon
                                                        onClick={() => {
                                                            setEditingMinPrice(true);
                                                        }}
                                                        className="partnerships-icon partnerships-gray-icon"
                                                    />
                                                )
                                            }
                                        />
                                        <Input
                                            readOnly={!!currentList.id && !editingMaxPrice}
                                            label="Maximum Price"
                                            value={currentList.maxPrice}
                                            onChange={(value) => {
                                                updateCurrentList({ key: 'maxPrice', value });
                                            }}
                                            type="number"
                                            RightIcon={
                                                !currentList.id ? null : editingMaxPrice ? (
                                                    <CheckRoundedIcon
                                                        onClick={() => {
                                                            setEditingMaxPrice(false);
                                                            saveCuratedListInfoChanges('maxPrice');
                                                        }}
                                                        className="partnerships-icon partnerships-gray-icon"
                                                    />
                                                ) : (
                                                    <EditRoundedIcon
                                                        onClick={() => {
                                                            setEditingMaxPrice(true);
                                                        }}
                                                        className="partnerships-icon partnerships-gray-icon"
                                                    />
                                                )
                                            }
                                        />
                                    </>
                                )}

                                <Select
                                    label="List Type"
                                    value={currentList.type}
                                    onChange={({ selectedItem }) => {
                                        updateCurrentList({ key: 'type', value: selectedItem.value });
                                    }}
                                    readOnly={Object.keys(currentList.slots).length > 0}
                                    required={true}
                                    options={[
                                        { value: 'Taxonomy', text: 'Taxonomy' },
                                        { value: 'Item', text: 'Item' },
                                    ]}
                                />
                            </div>
                            {!!listInfoError && <div className="fam-warning-text grotesk-regular">{listInfoError}</div>}

                            <div className="header-text grotesk-bold">List Contents</div>

                            <div className="list-contents-container">
                                <div className="list-contents-left">
                                    <div>
                                        <Select
                                            label="Slot Number"
                                            options={slotOptions}
                                            required={true}
                                            value={currentSlot}
                                            onChange={({ selectedItem }) => {
                                                resetSlot(selectedItem.value);
                                            }}
                                        />
                                    </div>

                                    {currentList.type === 'Taxonomy' && (
                                        <TaxonomySelector
                                            taxonomySelections={taxonomySelections}
                                            setTaxonomySelections={setTaxonomySelections}
                                            taxonomy={taxonomy}
                                            taxonomySlotOrderBy={taxonomySlotOrderBy}
                                            handleTaxonomyOrderByChange={handleTaxonomyOrderByChange}
                                        />
                                    )}

                                    {currentList.type === 'Item' && (
                                        <div>
                                            <div className="small-header-text grotesk-bold">
                                                Add {currentList.type === 'Taxonomy' && 'Non Taxonomy'} Item
                                            </div>
                                            <div>
                                                <MultiSelect
                                                    label="Facilities"
                                                    options={famAccount?.facilities?.map((item) => ({
                                                        value: item.id,
                                                        text: item.name,
                                                    }))}
                                                    value={selectedFacilities}
                                                    multiple={true}
                                                    onChange={({ selectedItems }) => {
                                                        setSelectedFacilities(selectedItems.map((item) => item.value));
                                                    }}
                                                />
                                            </div>
                                            {currentList.type === 'Item' && (
                                                <TaxonomySelector
                                                    taxonomySelections={taxonomySelections}
                                                    setTaxonomySelections={setTaxonomySelections}
                                                    taxonomy={taxonomy}
                                                />
                                            )}
                                            <div>
                                                <DropdownMultipleCombobox
                                                    label="Add Item"
                                                    OptionComponent={<ItemDetails />}
                                                    options={commonItems
                                                        .filter((item) => {
                                                            let currentItemsInSlot = Object.values(
                                                                selectedItemsByPackGroup
                                                            )
                                                                .map((arr) => arr.map((item) => item.externalSku))
                                                                .flat();

                                                            return !currentItemsInSlot.includes(item.externalSku);
                                                        })
                                                        .map((item) => ({
                                                            value: item.externalSku,
                                                            text: item.name,
                                                            ...item,
                                                        }))}
                                                    value={selectedItemsToAdd}
                                                    onChange={({ selectedItems, resetInputValue }) => {
                                                        setSelectedItemsToAdd(
                                                            selectedItems
                                                                .filter((item) => !!item)
                                                                .map((item) => item.value)
                                                        );
                                                        if (!resetItemInputValue) {
                                                            setResetItemInputValue(() => resetInputValue);
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div style={{ paddingTop: '10px' }}>
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        addSelectedItemToSelectedPackGroups();
                                                        if (!!resetItemInputValue) {
                                                            resetItemInputValue();
                                                        }
                                                    }}
                                                    className="fam-button fam-button-white grotesk-bold"
                                                >
                                                    Add Item{selectedItemsToAdd.length > 1 && 's'} To Slot
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="list-contents-right">
                                    <PartnershipTabs tabs={packGroupListTabs} />
                                </div>
                            </div>
                        </form>
                        <div className="add-slot-button-container">
                            {currentList.slots.hasOwnProperty(currentSlot) && (
                                <button
                                    style={{ float: 'left' }}
                                    type="button"
                                    className="fam-button fam-button-red grotesk-bold"
                                    onClick={() => {
                                        setRemoveSlotModalOpen(true);
                                    }}
                                >
                                    Delete Slot
                                </button>
                            )}
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => {
                                                setCurrentSlotRequired(!currentSlotRequired);
                                            }}
                                            checked={currentSlotRequired}
                                        />
                                    }
                                    label="Required?"
                                />
                                <button
                                    type="button"
                                    className="fam-button fam-button-yellow grotesk-bold"
                                    onClick={handleAddSlotToList}
                                    disabled={!currentSlot}
                                >
                                    {Object.keys(currentList.slots).includes(currentSlot)
                                        ? 'Save Changes to Slot'
                                        : 'Add Slot To List'}
                                </button>
                            </div>
                        </div>
                        {!!addItemError && <div className="fam-warning-text grotesk-regular">{addItemError}</div>}
                    </div>

                    {Object.keys(currentList.slots).length > 0 && (
                        <div className="create-list-summary">
                            <div className="grotesk-bold header-text">List Summary</div>
                            <PartnershipTabs
                                tabs={packGroupsAndItems
                                    .filter((pg) => !!pg.packGroupID)
                                    .map((pg) => {
                                        return {
                                            name: pg.facilityName,
                                            component: (
                                                <ListSummary
                                                    key={pg.packGroupID}
                                                    currentList={currentList}
                                                    week={week}
                                                    pg={pg}
                                                    notices={currentList?.notices?.[pg.packGroupID] || []}
                                                />
                                            ),
                                            warning: currentList?.notices?.[pg.packGroupID]?.length > 0,
                                        };
                                    })}
                            />
                        </div>
                    )}

                    {!currentList.id && Object.keys(currentList.slots).length > 0 && (
                        <div className="save-list-button-container">
                            <button
                                type="button"
                                className="fam-button fam-button-yellow grotesk-bold"
                                onClick={handleCreateList}
                            >
                                Create List
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const TaxonomySelector = ({
    taxonomySelections,
    setTaxonomySelections,
    taxonomy,
    taxonomySlotOrderBy,
    // setTaxonomySlotOrderBy,
    handleTaxonomyOrderByChange,
}) => {
    const getTaxonomyOptions = (level) => {
        const parentIDs = level > 1 ? taxonomySelections[`l${level - 1}`] : [0];
        let opts = taxonomy[`l${level}`]
            .filter((item) => parentIDs.includes(item.parentID))
            .map((item) => ({ text: item.name, value: item.id }));
        return opts;
    };

    return (
        <div className="list-contents-taxonomy">
            <div>
                <DropdownMultipleCombobox
                    label="Taxonomy 1"
                    options={getTaxonomyOptions(1)}
                    value={taxonomySelections.l1}
                    onChange={({ selectedItems, resetInputValue }) => {
                        setTaxonomySelections({
                            l1: selectedItems.map((item) => item.value),
                            l2: [],
                            l3: [],
                            l4: [],
                        });
                        resetInputValue();
                    }}
                />
            </div>
            <div>
                <DropdownMultipleCombobox
                    label="Taxonomy 2"
                    options={getTaxonomyOptions(2)}
                    value={taxonomySelections.l2}
                    onChange={({ selectedItems, resetInputValue }) => {
                        setTaxonomySelections({
                            l1: taxonomySelections.l1,
                            l2: selectedItems.map((item) => item.value),
                            l3: [],
                            l4: [],
                        });
                        resetInputValue();
                    }}
                />
            </div>
            <div>
                <DropdownMultipleCombobox
                    label="Taxonomy 3"
                    options={getTaxonomyOptions(3)}
                    value={taxonomySelections.l3}
                    onChange={({ selectedItems, resetInputValue }) => {
                        setTaxonomySelections({
                            l1: taxonomySelections.l1,
                            l2: taxonomySelections.l2,
                            l3: selectedItems.map((item) => item.value),
                            l4: [],
                        });
                        resetInputValue();
                    }}
                />
            </div>
            <div>
                <DropdownMultipleCombobox
                    label="Taxonomy 4"
                    options={getTaxonomyOptions(4)}
                    value={taxonomySelections.l4}
                    onChange={({ selectedItems, resetInputValue }) => {
                        setTaxonomySelections({
                            l1: taxonomySelections.l1,
                            l2: taxonomySelections.l2,
                            l3: taxonomySelections.l3,
                            l4: selectedItems.map((item) => item.value),
                        });
                        resetInputValue();
                    }}
                />
            </div>
            {!!taxonomySlotOrderBy && (
                <div>
                    <Select
                        label="Sku Order"
                        value={taxonomySlotOrderBy}
                        onChange={({ selectedItem }) => {
                            handleTaxonomyOrderByChange(selectedItem.value);
                        }}
                        required={true}
                        options={[
                            { value: 'popularity', text: 'Popularity' },
                            { value: 'price', text: 'Price' },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

const ListSummary = ({ currentList, week, pg, notices }) => {
    let listTotal = 0;

    let slotsForPackGroup = {};
    Object.keys(currentList.slots)
        .sort((a, b) => (Number(a) < Number(b) ? -1 : 1))
        .forEach((slotKey) => {
            let slotItems =
                currentList.slots[slotKey]?.packGroups?.find((item) => item.packGroupID === pg.packGroupID)?.items ||
                [];
            let required = currentList.slots[slotKey]?.required && slotItems.length > 0;

            slotsForPackGroup[slotKey] = { items: slotItems, required };
            let positionOneItem = slotItems.find((item) => item.position === 1);
            if (!!positionOneItem) {
                listTotal += positionOneItem.price * positionOneItem.qty;
            }
        });

    return (
        <div className="create-list-summary-pack-group-container" key={pg.packGroupID}>
            <div className="grotesk-bold">
                Week {week}: {pg.facilityName}
            </div>

            <div>List Total: ${listTotal.toFixed(2)}</div>

            {notices.length > 0 && (
                <ExpandableNotice message="Please fill empty slots in list" expnadedNotices={notices} />
            )}

            {Object.keys(slotsForPackGroup).map((slotKey) => {
                let slotForPackGroup = slotsForPackGroup[slotKey];
                return (
                    <div key={slotKey}>
                        <div className="grotesk-bold create-list-slot-container">
                            Slot {slotKey}
                            {slotForPackGroup.required && ` (Required)`}:{' '}
                            {currentList.type === 'Taxonomy' && (
                                <span>{currentList?.slots?.[slotKey]?.taxonomyNames?.join(', ')}</span>
                            )}
                        </div>
                        {slotForPackGroup.items
                            .sort((a, b) => (a.position < b.position ? -1 : 1))
                            .map((item) => {
                                if (!item.externalSku) {
                                    return <div className="fam-warning-text grotesk-regular">Empty Position</div>;
                                }
                                return (
                                    <div key={item.position}>
                                        <div className="grotesk-regular create-list-slot-item-name">
                                            {item.name}, Qty: {item.qty}
                                        </div>
                                        {item.position === 1 && (
                                            <div className="grotesk-regular create-list-slot-item-price">
                                                ${(item.price * item.qty).toFixed(2)}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                );
            })}
        </div>
    );
};

const Prop65Warnings = ({ item }) => {
    return (
        <div>
            {item.prop65WarningCategoryFood && !item.prop65Ingredients.length && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> California Residents, click{' '}
                        <a className="gray-text" href="/california-proposition-65">
                            here
                        </a>{' '}
                        to learn more about Proposition 65.
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryNonFood && !item.prop65Ingredients && (
                <div>
                    <img
                        className="warning"
                        src="https://static.misfitsmarket.com/images/MM_icon-warning-sm.png"
                        alt="Warning"
                    />
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> This product contains one or more chemicals known to the
                        State of California to cause cancer and birth defects or other reproductive harm. For more
                        information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.p65warnings.ca.gov/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.P65warnings.ca.gov
                        </a>
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryFood && item.prop65Ingredients.length > 0 && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> This product contains chemicals including{' '}
                        {item.prop65Ingredients.join(', ')}, which {item.prop65Ingredients.length > 1 ? 'are' : 'is'}{' '}
                        known to the State of California to cause cancer and birth defects or other reproductive harm.
                        For more information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.p65warnings.ca.gov/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.P65warnings.ca.gov
                        </a>
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryCanned && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> Many food and beverage cans have linings containing
                        bisphenol A (BPA), a chemical known to the State of California to cause cancer and birth defects
                        or other reproductive harm. Jar lids and bottle caps may also contain BPA. You can be exposed to
                        BPA when you consume foods or beverages packaged in these containers. For more information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.p65warnings.ca.gov/BPA"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.p65warnings.ca.gov/BPA
                        </a>
                    </p>
                </div>
            )}

            {item.prop65WarningCategoryAlcohol && (
                <div>
                    <p className="body-xxsmall gray-text">
                        <b className="body-xsmall">WARNING:</b> Drinking distilled spirits, beer, coolers, wine, and
                        other alcoholic beverages may increase cancer risk and, if consumed during pregnancy, can cause
                        birth defects. For more information go to{' '}
                        <a
                            className="gray-text"
                            href="https://www.P65warnings.ca.gov/alcohol"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.P65warnings.ca.gov/alcohol
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};

const ItemDetails = ({ item }) => {
    return (
        <div className="partnerships-add-item-container">
            <div className="add-item-item-one">
                {!!item.photoUrlSmall && (
                    <img
                        alt={item.name}
                        style={{ width: '50px', height: '50px' }}
                        src={`https://static.misfitsmarket.com/${item.photoUrlSmall}`}
                    />
                )}
            </div>
            <div className="add-item-item-two">
                <div className="grotesk-bold">{item.name}</div>
                {item.brand && (
                    <div>
                        <span className="grotesk-bold">Brand:</span> {item.brand}
                    </div>
                )}
                {item.ingredients && (
                    <div>
                        <span className="grotesk-bold">Ingredients:</span> {item.ingredients}
                    </div>
                )}
                {item.description && (
                    <div>
                        <span className="grotesk-bold">Description:</span> {item.description}
                    </div>
                )}
                {item.allergens && (
                    <div>
                        <span className="grotesk-bold">Allergens:</span> {item.allergens}
                    </div>
                )}
                {item.crossAllergens && (
                    <div>
                        <span className="grotesk-bold">Cross Allergens:</span> {item.crossAllergens}
                    </div>
                )}
                {(item.photoUrlSmall || item.photoUrlNutritionalInfo) && (
                    <div>
                        <span className="grotesk-bold">Photos: </span>
                        {item.photoUrlSmall && (
                            <a target="_blank" href={`https://static.misfitsmarket.com/${item.photoUrlSmall}`}>
                                Product Photo
                            </a>
                        )}
                        &nbsp;&nbsp;
                        {item.photoUrlNutritionalInfo && (
                            <a
                                target="_blank"
                                href={`https://static.misfitsmarket.com/${item.photoUrlNutritionalInfo}`}
                            >
                                Nutritional Info
                            </a>
                        )}
                    </div>
                )}
                <Prop65Warnings item={item} />
            </div>
        </div>
    );
};

export { CreateList };
