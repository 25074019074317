import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Modal, Button, NativeSelect } from '@material-ui/core';
import { fbmApi as api } from '../../../api/fbm';
import { procurementApi as procureApi } from '../../../api/procurement';
import { UserContext } from '../../../contexts/UserContext';

// The modal to add items to a purchase order
const AddItemModal = ({ parentRow, asnID, closeModal, items, getRows, fbmAccount }) => {
    let [error, setError] = useState(null);

    let formFields = [
        {
            name: 'sku',
            inputElement: 'autocomplete',
            label: 'Item',
            gridValue: 12,
            inputProps: { required: true, opts: items },
        },
        {
            name: 'qty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'csPallet',
            inputElement: 'textField',
            label: 'Cases per pallet',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'numOfPallets',
            inputElement: 'textField',
            label: 'Num Of Pallets',
            gridValue: 2,
            inputProps: { type: 'number', integer: true },
        },
        { name: 'expectedExpirationDate', inputElement: 'date', label: 'Expected Expiration Date', gridValue: 6 },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let items = {};
                        items['items'] = [formData];
                        items['fbmAccount'] = fbmAccount.fbmAccountName;

                        let response = await api.addItemToASN(asnID, items);
                        if (response.status === false) {
                            let message = 'Error adding item';
                            if (response.msg) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                            return;
                        }

                        getRows();
                        closeModal();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

// The table to show items
const ViewItemsTable = ({ parentRow, setExpandedRow, fbmAccount, onEditRow }) => {
    const [asnID, setASNID] = useState(parentRow.original.id);
    const [rows, setRows] = useState([]);
    const [addItemModalOpen, setAddItemModalOpen] = useState(false);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [items, setItems] = useState([]);
    const [itemsAutocomplete, setItemsAutocomplete] = useState([]);

    const getItems = async () => {
        let response = await api.getItems(fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setItems([]);
            return;
        }
        setItems(response.data.rows);

        let itemsAsOpts = response.data.rows.map((item) => {
            return { text: `SKU ${item.thirdPartyItemID}: ${item.name}`, value: item.thirdPartyItemID };
        });
        setItemsAutocomplete(itemsAsOpts);
    };

    const getRows = async () => {
        let response = await api.getASNDetails(asnID, fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(
            (response.data.output.items ?? []).map((item) => {
                if (item.qty !== null && item.qty !== undefined) {
                    if (item.unitsPerCase !== null && item.unitsPerCase !== undefined) {
                        return { ...item, totalUnits: item.qty * item.unitsPerCase };
                    } else {
                        return { ...item, totalUnits: item.qty * item.baseUnitsPerCase };
                    }
                }
                return item;
            })
        );
    };

    const columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'sku', Header: 'Item SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'itemName', Header: 'Name' },
        {
            accessor: 'expectedExpirationDate',
            Header: 'Expected Expiration',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                required: true,
            },
        },
        {
            accessor: 'qty',
            Header: 'Quantity',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                required: true,
            },
        },
        {
            accessor: 'unitsPerCase',
            Header: 'Units Per Case',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                required: false,
            },
        },
        { accessor: 'csPallet', Header: 'Cases per pallet', editable: true },
        {
            accessor: 'numOfPallets',
            Header: 'Num Of Pallets',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'totalUnits',
            Header: 'Total Units',
            editable: false,
        },
        { accessor: 'partnerLotNumber', Header: 'Partner Lot Number', editable: true },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={async () => {
                            let response = await api.removeASNItem(asnID, row.original.id, fbmAccount.fbmAccountName);

                            if (response.status === false) {
                                setNotification({ text: "Can't remove item " + response.msg, level: 'error' });
                                return;
                            }
                            onEditRow();
                            getRows();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        getItems();
        getRows();
    }, []);

    return (
        <div>
            <Notifications options={notification} />
            {addItemModalOpen && (
                <AddItemModal
                    fbmAccount={fbmAccount}
                    getRows={() => {
                        onEditRow();
                        getRows();
                    }}
                    parentRow={parentRow}
                    asnID={asnID}
                    items={itemsAutocomplete}
                    closeModal={() => {
                        setAddItemModalOpen(false);
                    }}
                />
            )}
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let updates = { [field]: value };
                    updates.fbmAccount = fbmAccount.fbmAccountName;
                    let response = await api.modifyASNItem(asnID, id, updates);
                    if (response.status === false) {
                        let message = 'Error modifying ASN';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                        return false;
                    }
                    onEditRow();
                    getRows();
                    return true;
                }}
            />
            <Button
                onClick={() => {
                    setAddItemModalOpen(true);
                }}
            >
                Add Item
            </Button>
        </div>
    );
};

const EditDeliveryModal = ({ rowData, closeModal, getRows, fbmAccount }) => {
    let [error, setError] = useState(null);

    let date = moment(rowData.scheduledDeliveryDate).format('YYYY-MM-DD');
    let time = moment(rowData.scheduledDeliveryDate).format('HH:mm');

    let formFields = [
        {
            name: 'asnID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'ASN ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'scheduledDeliveryDate',
            initialValue: date,
            inputElement: 'date',
            label: 'Scheduled Delivery Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            initialValue: time,
            inputElement: 'time',
            label: 'Delivery time',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['scheduledDeliveryDate'] = formData.scheduledDeliveryDate;
                        item['deliverTime'] = formData.scheduledTime;
                        item['fbmAccount'] = fbmAccount.fbmAccountName;

                        let response = await api.modifyASN(formData.asnID, item);
                        if (response.status === false) {
                            let message = 'Error modifying ASN';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError(message);
                        }

                        closeModal();
                        getRows();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const EditSpecialInstructionsModal = ({ rowData, closeModal, fbmAccount }) => {
    let [error, setError] = useState(null);

    let specialInstructions = rowData.specialInstructions;

    let formFields = [
        {
            name: 'asnID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'ASN ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'specialInstructions',
            initialValue: specialInstructions,
            inputElement: 'textField',
            label: 'Special Instructions',
            gridValue: 8,
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['specialInstructions'] = formData.specialInstructions;
                        item['fbmAccount'] = fbmAccount.fbmAccountName;

                        let response = await api.modifyASN(formData.asnID, item);
                        if (response.status === false) {
                            let message = 'Error modifying ASN';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError(message);
                            return;
                        }

                        closeModal();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const CancelPOModal = ({ rowData, closeModal, setNotification, fbmAccount }) => {
    return (
        <Modal open={true} onClose={closeModal}>
            <div
                style={{
                    outline: 0,
                    backgroundColor: 'white',
                    width: '300px',
                    margin: '10% auto',
                    padding: '10px',
                    textAlign: 'center',
                }}
            >
                <div style={{ alignItems: 'center' }}>
                    <h2>Are you sure you want to cancel?</h2>
                    <br />
                    <div>
                        <Button
                            onClick={async () => {
                                let response = await api.cancelASN(rowData.original.id, fbmAccount.fbmAccountName);
                                if (response.status === false) {
                                    setNotification({ text: 'Failed to cancel ' + response.msg, level: 'error' });
                                    return;
                                }

                                setNotification({ text: 'ASN cancelled', level: 'success' });
                                closeModal();
                            }}
                        >
                            YES
                        </Button>
                        <Button onClick={() => closeModal()}>NO</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// The main PO view
let ViewASN = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [editDeliveryModalOpen, setEditDeliveryModalOpen] = useState(false);
    let [editSpecialInstructionsModalOpen, setEditSpecialInstructionsModalOpen] = useState(false);
    let [cancelPOModalOpen, setCancelPOModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [shippers, setShippers] = useState([]);
    const { fbmAccount, user } = useContext(UserContext);

    // Load purchase orders
    let getRows = async () => {
        let response = await api.getASNs(fbmAccount.fbmAccountName);

        if (response.status === false) {
            setNotification({ text: "Can't access ASNs: " + response.msg, level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
                row.receivedDate = row.receivedDate ? moment(row.receivedDate.date).format('MM/DD/YYYY HH:mm:ss') : '';
                row.scheduledDeliveryDate = moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY HH:mm:ss');
                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        if (fbmAccount?.id) {
            getRows();
            getShippers();
        }
    }, [fbmAccount]);

    // Load shippers
    let getShippers = async () => {
        let response = await procureApi.getShippers();
        if (response.status === false) {
            setNotification({ text: 'No shippers found ' + response.msg, level: 'error' });
            setShippers([]);
            return;
        }

        let allowedShipperIDs = fbmAccount.inboundShipperIDs;

        let shippersAsOpts = response.data.rows
            .map((row) => {
                row.text = row.name;
                row.value = row.id;
                return row;
            })
            .filter((item) => {
                return allowedShipperIDs.includes(item.value);
            });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setShippers([pleaseSelect].concat(shippersAsOpts));
    };

    let closeDeliveryEditModal = () => {
        setEditDeliveryModalOpen(false);
    };

    let showDeliveryEditModal = (row, closeDeliveryEditModal) => {
        setSelectedRow(row);
        setEditDeliveryModalOpen(true);
    };

    let closeSpecialInstructionsEditModal = () => {
        setEditSpecialInstructionsModalOpen(false);
    };

    let showSpecialInstructionsEditModal = (row, closeSpecialInstructionsEditModal) => {
        setSelectedRow(row);
        setEditSpecialInstructionsModalOpen(true);
    };

    let showCancelPOModal = (row) => {
        setSelectedRow(row);
        setCancelPOModalOpen(true);
    };

    let columns = [
        { accessor: 'id', Header: 'ASN ID' },
        { accessor: 'facilityName', Header: 'Facility' },
        { accessor: 'createdAt', Header: 'Created On' },
        { accessor: 'status', Header: 'Status' },
        {
            accessor: 'scheduledDeliveryDate',
            Header: 'Scheduled Delivery',
            editable: true,
            customEdit: showDeliveryEditModal,
        },
        {
            accessor: 'receivedDate',
            Header: 'Received Date',
            editable: false,
            Cell: ({ row, column, saveEdit, editable }) => {
                if (row.values?.status === 'RECEIVED' && row.values?.receivedDate) {
                    return row.values.receivedDate;
                }

                return '';
            },
        },
        {
            accessor: 'shipperID',
            Header: 'Shipper',
            editable: true,
            editProps: {
                type: 'select',
                options: shippers,
            },
        },

        {
            accessor: 'minTempReq',
            Header: 'Min Temperature',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'maxTempReq',
            Header: 'Max Temperature',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'totalPallets',
            Header: 'Total Pallets',
            editable: false,
        },
        {
            accessor: 'specialInstructions',
            Header: 'Special Instructions',
            editable: true,
            customEdit: showSpecialInstructionsEditModal,
            width: '150px',
        },
        {
            accessor: 'download',
            hiddenColumn: user.fbm,
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                const [downloadOpt, setDownloadOpt] = useState('csv');
                const downloadOptions = ['csv', 'pdf', 'ship-list'];
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <NativeSelect
                            id={`Download_Select_${row.id}`}
                            className="input-element-select"
                            value={downloadOpt}
                            label="Download"
                            onChange={(event) => {
                                setDownloadOpt(event.target.value);
                            }}
                        >
                            {downloadOptions.map((value, i) => {
                                return (
                                    <option key={i} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                            ;
                        </NativeSelect>
                        <Button
                            id={`Download_${row.id}`}
                            onClick={() => {
                                if (downloadOpt === 'csv') {
                                    procureApi.downloadPOCSV(row.original.id);
                                } else if (downloadOpt === 'pdf') {
                                    procureApi.downloadPOPDF(row.original.id);
                                } else if (downloadOpt === 'ship-list') {
                                    procureApi.downloadPOShipList(row.original.id);
                                } else {
                                    console.log('Unrecognized download option ' + downloadOpt);
                                }
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Cancel_${row.id}`}
                            onClick={async () => {
                                showCancelPOModal(row);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <h1>{fbmAccount.companyName} - Advance Shipping Notifications</h1>
            <Notifications options={notification} />
            {}
            <div>
                {editDeliveryModalOpen && (
                    <EditDeliveryModal
                        rowData={selectedRow}
                        getRows={getRows}
                        closeModal={() => {
                            setEditDeliveryModalOpen(false);
                        }}
                        fbmAccount={fbmAccount}
                    />
                )}
                {editSpecialInstructionsModalOpen && (
                    <EditSpecialInstructionsModal
                        rowData={selectedRow}
                        closeModal={() => {
                            setEditSpecialInstructionsModalOpen(false);
                            getRows();
                        }}
                        fbmAccount={fbmAccount}
                    />
                )}
                {cancelPOModalOpen && (
                    <CancelPOModal
                        fbmAccount={fbmAccount}
                        rowData={selectedRow}
                        setNotification={setNotification}
                        closeModal={() => {
                            setCancelPOModalOpen(false);
                            getRows();
                        }}
                    />
                )}
                <DataTable
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={<ViewItemsTable fbmAccount={fbmAccount} onEditRow={getRows} />}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let item = {};
                        item[field] = value;
                        item['fbmAccount'] = fbmAccount.fbmAccountName;

                        let response = await api.modifyASN(id, item);
                        if (response.status === false) {
                            let message = 'Error modifying ASN';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        }

                        await getRows();
                        return response.status;
                    }}
                />
            </div>
        </div>
    );
};

export { ViewASN };
