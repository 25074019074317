import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let partnershipsApi = {
    getDrinksItems: async () => {
        let url = `api/partnerships/v1/drinks/items`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateDrinksItem: async (id, sendParams) => {
        let url = `api/partnerships/v1/drinks/items/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    getNewDrinksItems: async () => {
        let url = `api/partnerships/v1/drinks/items`;
        let response = await mmFetchWithResponse(url, { method: 'POST' });
        return response;
    },
    cancelImportedShipment: async (shipmentID) => {
        let url = 'api/partnerships/v1/import/orders/cancel';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ shipmentID }),
        });
        return response;
    },
    confirmOrderUpload: async (key) => {
        let url = 'api/partnerships/v1/import/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    getImportedOrders: async () => {
        let url = 'api/partnerships/v1/import/orders';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrderImportSources: async (key) => {
        let url = 'api/partnerships/v1/import/sources';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateOrderImportSource: async (id, sendParams) => {
        let url = `api/partnerships/v1/import/sources/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createImportedOrderSource: async (sendParams) => {
        let url = 'api/partnerships/v1/import/sources';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    uploadOrders: async (data) => {
        let formData = new FormData();
        formData.append('itemFile', data.file);
        formData.append('source', data.source);

        let url = 'api/partnerships/v1/import';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadSubscriptions: async (data) => {
        let formData = new FormData();
        formData.append('itemFile', data.file);
        formData.append('source', data.source);

        let url = `api/partnerships/v1/fam/subscriptions/import?source=${data.source}`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    confirmSubscriptionsUpload: async (key, source) => {
        let url = `api/partnerships/v1/fam/subscriptions/import/confirm?source=${source}`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
    },
    getSources: async (sourceName = '') => {
        let url = `api/partnerships/v1/fam/sources?sourceName=${sourceName}`;
        return mmFetchWithResponse(url);
    },
    getSubscriptionOptions: async (source) => {
        let url = `api/partnerships/v1/fam/subscription/options?source=${source}`;
        return mmFetchWithResponse(url);
    },
    getDeliverySchedulePreview: async (deliveryDay, numOrders, frequency, zipCode, id) => {
        let url = `api/partnerships/v1/fam/subscription/preview?day=${deliveryDay}&zip=${zipCode}&numOrders=${numOrders}&frequency=${frequency}`;
        if (!!id) {
            url += `&id=${id}`;
        }
        return mmFetchWithResponse(url);
    },
    simulateSubscriptionOrders: async (source, sendParams) => {
        let url = `api/partnerships/v1/fam/subscription/simulate?source=${source}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createSubscription: async (source, sendParams) => {
        let url = `api/partnerships/v1/fam/subscription?source=${source}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    editSubscription: async (subID, sendParams, source) => {
        let url = `api/partnerships/v1/fam/subscription/${subID}?source=${source}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    cancelOrder: async (source, subID, orderID) => {
        let url = `api/partnerships/v1/fam/subscription/${subID}/orders/${orderID}/cancel?source=${source}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
        });
    },
    cancelSubscription: async (source, subID) => {
        let url = `api/partnerships/v1/fam/subscription/${subID}/cancel?source=${source}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
        });
    },
    getPartnershipOrders: async (source, page, search, filters, orderBy) => {
        let url = `api/partnerships/v1/fam/orders?source=${source}&page=${page}&pageSize=20&order=${orderBy}`;
        if (!!search) {
            url += `&search=${encodeURIComponent(search)}`;
        }
        Object.keys(filters).forEach((key) => {
            const value = filters[key];
            url += `&${key}=${encodeURIComponent(value)}`;
        });
        return mmFetchWithResponse(url);
    },
    getShipmentDetailsForOrder: async (orderID) => {
        let url = `api/partnerships/v1/fam/orders/${orderID}`;
        return mmFetchWithResponse(url);
    },
    addToSkuExclusionList: async (listID, externalSku, source) => {
        const url = `api/partnerships/v1/fam/lists/${listID}/sku-exclusion/${externalSku}?source=${source}`;
        return mmFetchWithResponse(url, { method: 'POST' });
    },
    removeFromSkuExclusionList: async (listID, externalSku, source) => {
        const url = `api/partnerships/v1/fam/lists/${listID}/sku-exclusion/${externalSku}?source=${source}`;
        return mmFetchWithResponse(url, { method: 'DELETE' });
    },
    getSkuExclusionList: async (listID, source) => {
        const url = `api/partnerships/v1/fam/lists/${listID}/sku-exclusion?source=${source}`;
        return mmFetchWithResponse(url);
    },
    createCuratedList: async (sendParams) => {
        let url = `api/partnerships/v1/fam/curatedList`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    getCuratedListsForPartner: async (source, listID, page, searchTerm, active) => {
        let url = `api/partnerships/v1/fam/lists?source=${source}&listID=${listID}&page=${page}&pageSize=20&active=${active}`;
        if (!!searchTerm) {
            url += `&name=${searchTerm}`;
        }
        return await mmFetchWithResponse(url);
    },
    getSubscriptionsForPartner: async (source, page, searchTerm, subID = '', filters = {}, exportToCsv = false) => {
        let url = `api/partnerships/v1/fam/subscription?source=${source}`;

        if (exportToCsv) {
            url += '&export=true';
        } else {
            url += `&page=${page}&pageSize=20`;
        }
        if (!!subID) {
            url += `&id=${subID}`;
        }
        if (!!searchTerm) {
            url += `&name=${searchTerm}`;
        }

        Object.keys(filters).forEach((key) => {
            const value = filters[key];
            url += `&${key}=${encodeURIComponent(value)}`;
        });

        if (exportToCsv) {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'subscriptions.csv';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } else {
            return mmFetchWithResponse(url);
        }
    },
    getSubscriptionOrders: async (source, subID) => {
        let url = `api/partnerships/v1/fam/subscription/${subID}/orders?source=${source}`;
        return mmFetchWithResponse(url);
    },
    getEmptyItemsForPartner: async (source) => {
        let url = `api/partnerships/v1/fam/lists/empty?source=${source}`;
        return mmFetchWithResponse(url);
    },
    getCuratedListsForPartnerCSV: async (source) => {
        let url = `api/partnerships/v1/fam/lists?source=${source}&csv=true`;
        let filename = `lists.csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[getItemsWithTaxonomyForWeekYearCSV] error', e.toString());
        }
    },
    getValidDeliveryDaysForZip: async (zip) => {
        let url = `api/partnerships/v1/fam/subscription/validDays?zip=${zip}`;
        return mmFetchWithResponse(url);
    },
    addSlotToPartnershipList: async (
        listID,
        slotNumber,
        required,
        packGroupItems,
        taxonomyIDs,
        taxonomyLevel,
        taxonomyOrderBy
    ) => {
        let url = `api/partnerships/v1/fam/lists/${listID}/slot/${slotNumber}`;
        return await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ required, packGroupItems, taxonomyIDs, taxonomyLevel, taxonomyOrderBy }),
        });
    },
    removeSlotFromPartnershipList: async (listID, slotNumber) => {
        let url = `api/partnerships/v1/fam/lists/${listID}/slot/${slotNumber}`;
        return await mmFetchWithResponse(url, { method: 'DELETE' });
    },
    getFAQs: async () => {
        let url = `api/partnerships/v1/fam/faqs`;
        return mmFetchWithResponse(url);
    },
    getDeliveryScheduleCSV: async () => {
        let url = `api/partnerships/v1/fam/deliverySchedule`;
        let filename = 'deliverySchedule.csv';
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[getDeliveryScheduleCSV] error', e.toString());
        }
    },
    getItemsWithTaxonomy: async (source) => {
        let url = `api/partnerships/v1/fam/taxonomyItems?source=${source}`;
        return await mmFetchWithResponse(url);
    },
    getItemsWithTaxonomyCSV: async (source) => {
        let url = `api/partnerships/v1/fam/taxonomyItems?source=${source}&csv=true`;
        let filename = `inventory.csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[getItemsWithTaxonomyForWeekYearCSV] error', e.toString());
        }
    },
    duplicateList: async (listID, name) => {
        let url = `api/partnerships/v1/fam/lists/${listID}/duplicate`;
        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ name }),
        });
    },
    replaceListItems: async (replacementExtSku, itemsToReplace) => {
        let url = `api/partnerships/v1/fam/lists/item/replace`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                replacementSku: replacementExtSku,
                itemsToReplace: itemsToReplace,
            }),
        });
    },
    getImports: async (source) => {
        let url = `api/partnerships/v1/fam/imports?source=${source}`;
        return mmFetchWithResponse(url);
    },
    getImportByID: async (source, id) => {
        let url = `api/partnerships/v1/fam/imports/${id}?source=${source}`;
        return mmFetchWithResponse(url);
    },
    retryImportedRows: async (source, rowIDs, forceCreate) => {
        let url = `api/partnerships/v1/fam/imports/retry?source=${source}`;
        return mmFetchWithResponse(url, { method: 'POST', body: JSON.stringify({ rowIDs, forceCreate }) });
    },
    updateImportedRow: async (rowID, source, sendParams) => {
        let url = `api/partnerships/v1/fam/imports/row/${rowID}?source=${source}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
    },
    updateScheduledOrder: async (source, scheduledOrderID, sendParams) => {
        let url = `api/partnerships/v1/fam/subscription/orders/${scheduledOrderID}?source=${source}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
    },
    retryScheduledOrders: async (source, scheduledOrderIDs, forceCreate) => {
        let url = `api/partnerships/v1/fam/subscription/orders/retry?source=${source}`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ scheduledOrderIDs, forceCreate }),
        });
    },
    getFailedSubscriptionOrders: async (source) => {
        let url = `api/partnerships/v1/fam/subscription/failedOrders?source=${source}`;
        return mmFetchWithResponse(url);
    },
};

export { partnershipsApi };
